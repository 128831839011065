<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage galleries</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ galleries
                </div>
              </div>
              <div class="breadcrumb-right" v-if="checkIsAccessible('album', 'create')">
                <div class="card-toolbar">
                  <v-btn
                    @click="addAlbum()"
                    class="mt-4 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    album
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Title"
                    type="text"
                    dense
                    outlined
                    v-on:keyup.enter="getAlbums()"
                    v-model="search.title"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    :items="active_statuses"
                    item-text="title"
                    v-on:keyup.enter="getAlbums()"
                    item-value="value"
                    v-model="search.active"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4" class="text-right">
                  <v-btn
                    @click.prevent="getAlbums()"
                    class="btn btn-primary w-35 btn-search"
                    :loading="loading"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Title</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <template>
                  <draggable
                    :move="checkMove"
                    v-model="albums"
                    class="text-left"
                    tag="tbody"
                    handle=".handle"
                  >
                    <tr v-for="(album, index) of albums" :key="index">
                      <td scope="row" class="wrap-column px-3">
                        <i class="cursor-drag fas fa-sort handle pr-2"></i>
                        <a href="#" @click="detail(album.id)" class="mr-2">
                          {{ album.title }}</a
                        >&nbsp;
                        <i
                          class="fas fa-circle"
                          :class="album.is_active ? 'dot-active' : 'dot-inactive'"
                        ></i>
                      </td>
                      <!--                <td>
                  <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      right
                      no-flip
                  >
                    <template v-slot:button-content>
                      <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                    </template>
                    &lt;!&ndash;begin::Navigation&ndash;&gt;
                    <div class="navi navi-hover ">

                      <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('album' ,'show')">>
                        <a href="#" class="navi-link" @click="detail(album.id)">
                                                            <span class="navi-icon">
                                                                 <i class="fas fa-eye"></i>
                                                             </span>
                          <span class="navi-text"> View </span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('album' ,'edit')">>
                        <a href="#" class="navi-link" @click="editAlbum(album.id)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                          <span class="navi-text"> Edit </span>
                        </a>
                      </b-dropdown-text>

                      <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('album' ,'delete')">
                        <a href="#" class="navi-link"
                           @click="deleteAlbum(album.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                          <span class="navi-text">  Delete</span>
                        </a>
                      </b-dropdown-text>

                    </div>
                    &lt;!&ndash;end::Navigation&ndash;&gt;
                  </b-dropdown>

                </td>-->
                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text
                                tag="div"
                                class="navi-item"
                                v-if="checkIsAccessible('album', 'show')"
                              >
                                <a class="navi-link" @click="detail(album.id)">
                                  <span class="navi-icon">
                                    <i class="fas fa-eye"></i>
                                  </span>
                                  <span class="navi-text">View</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text
                                tag="div"
                                class="navi-item"
                                v-if="checkIsAccessible('album', 'edit')"
                              >
                                <a class="navi-link" @click="editAlbum(album.id)">
                                  <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text
                                tag="div"
                                class="navi-item"
                                v-if="checkIsAccessible('album', 'delete')"
                              >
                                <a class="navi-link" @click="deleteAlbum(album.id)">
                                  <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="albums.length == 0">
                      <td class="px-3 text-center" colspan="2">
                        <strong>No data available to display.</strong>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </table>
            </div>
          </div>
          <create-and-update ref="album" @refresh_album="getAlbums"></create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AlbumService from "@/core/services/album/AlbumService";
import CreateAndUpdate from "./CreateAndUpdate";
import draggable from "vuedraggable";

const albumService = new AlbumService();
export default {
  name: "table-example",
  display: "Table",
  order: 8,
  components: {
    CreateAndUpdate,
    draggable,
  },
  data() {
    return {
      albums: [],
      page: null,
      perPage: null,
      total: null,
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      search: {
        active: "active",
      },
      list: [
        { id: 1, name: "Abby", sport: "basket" },
        { id: 2, name: "Brooke", sport: "foot" },
        { id: 3, name: "Courtenay", sport: "volley" },
        { id: 4, name: "David", sport: "rugby" },
      ],
      dragging: false,
      loading: false,
    };
  },
  mounted() {
    this.getAlbums();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Album", route: "wizard-1" },
      { title: "Album" },
    ]);
  },
  methods: {
    getAlbums() {
      this.loading = true;
      albumService
        .paginate(this.search, this.page)
        .then((response) => {
          this.albums = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    addAlbum() {
      this.$refs["album"].showModal();
    },
    editAlbum(id) {
      this.$refs["album"].showModal(id);
    },

    deleteAlbum(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            albumService
              .delete(id)
              .then((response) => {
                this.getAlbums();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
    checkMove: function (evt) {
      console.log(evt);
      let futureIndex = parseInt(evt.draggedContext.futureIndex) + 1;
      let item = evt.draggedContext.element;
      let itemId = item.id;
      this.sort(itemId, futureIndex);
    },
    sort(id, index) {
      albumService
        .sort(id, index)
        .then((res) => {
          this.$snotify.success("Sorted");
          this.getAlbums();
        })
        .catch((err) => {
          this.$snotify.success("Sorted Error");
        });
    },
    detail(id) {
      this.$router.push({ name: "album-photo", params: { id: id } });
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
</style>
