<template>
  <div>
    <v-dialog v-model="dialog" max-width="700" scrollable>
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            {{ `${edit ? "Update" : "Add"} album` }}
            <hr />
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon @click="cancelForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <div class="row">
              <div class="col-12">
                <v-text-field
                  outlined
                  dense
                  label="Album name"
                  v-model="album.title"
                ></v-text-field>

                <span class="text-danger" v-if="$v.album.title.$error"
                  >This information is required</span
                >
              </div>
              <div class="col-12">
                <v-file-input
                  outlined
                  dense
                  prepend-inner-icon="fas fa-image "
                  prepend-icon=""
                  v-model="album.cover_image"
                  @change="checkSize($event)"
                  label="Cover image"
                ></v-file-input>
                <span class="text-danger" v-if="sizeExceed"
                  >Please upload a file size upto 20MB</span
                >
              </div>
              <div class="col-12 mt-4" v-if="album.cover_image_path">
                <img :src="album.cover_image_path['thumb']" alt />
              </div>

              <div class="col-12">
                <!--          <b-form-tags class="border border-dark" input-id="tags-basic" v-model="album.tags"></b-form-tags>-->
                <v-combobox
                  v-model="album.tags"
                  hide-selected
                  outlined
                  dense
                  deletable-chips
                  placeholder="Add tag"
                  label="Add tag"
                  multiple
                  small-chips
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Type an tag and press <kbd>enter</kbd> to add to this album
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </div>
              <div class="col-12">
                <label for="">Description</label>
                <ckeditor :config="editorConfig" v-model="album.description"></ckeditor>
                <!-- <span class="text-danger" v-if="$v.page.content.$error">Content is required</span> -->
              </div>
              <div class="col-12 mt-5">
                <v-col cols="6" md="4">
                  Status
                  <v-switch
                    v-model="album.is_active"
                    :label="album.is_active ? 'Active' : 'Inactive'"
                    color="primary"
                  ></v-switch>
                </v-col>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn btn-standard cancel-btn" depressed @click="cancelForm"
            >Cancel
          </v-btn>
          <v-btn
            class="text-white btn btn-primary"
            depressed
            v-if="checkIsAccessible('album', 'create')"
            :disabled="sizeExceed"
            @click="createOrUpdate"
            :loading="isBusy"
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import AlbumService from "@/core/services/album/AlbumService";

const albumService = new AlbumService();
export default {
  validations: {
    album: {
      title: { required },
    },
  },
  data() {
    return {
      edit: false,
      dialog: false,
      sizeExceed: false,
      isBusy: false,
      album: {
        title: null,
        is_active: true,
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
    };
  },
  methods: {
    cancelForm(){
      this.resetForm()
      this.hideModal()
    },
    openDialog() {
      this.dialog = true;
    },
    showModal(id = null) {
      this.openDialog();
      if (id) {
        this.edit = true;
        this.getAlbum(id);
      }else{
        this.resetForm()
      }
    },
    hideModal() {
      this.dialog = false;
    },
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    getAlbum(id) {
      albumService.show(id).then((response) => {
        this.album = response.data.album;
        if (this.album.tags) {
          this.album.tags = this.album.tags.split(",");
        }
      });
    },
    generateFd() {
      // console.log(this.album)
      let fd = new FormData();
      for (let key in this.album) {
        if (
          key == "cover_image" &&
          this.album["cover_image"] &&
          this.album["cover_image"] != null
        ) {
          fd.append("cover_image", this.album[key]);
        } else if (key == "is_active" && this.album["is_active"]) {
          fd.append("is_active", this.album ? 1 : 0);
        } else {
          if (this.album[key] != null) {
            fd.append(key, this.album[key]);
          }
        }
      }
      return fd;
    },
    createOrUpdate() {
      this.$v.album.$touch();
      if (this.$v.album.$error) {
        setTimeout(() => {
          this.$v.album.$reset();
        }, 3000);
      } else {
        let fd = this.generateFd();
        if (this.edit) {
          this.updateAlbum(fd);
        } else {
          this.createAlbum(fd);
        }
      }
    },
    updateAlbum(fd) {
      this.isBusy = true;
      albumService
        .update(this.album.id, fd)
        .then((response) => {
          this.isBusy = false;
          this.$snotify.success("Information updated");
          this.resetForm();
          this.hideModal();
          this.$emit("refresh_album");
        })
        .catch((error) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
          // console.log(error);
        });
    },

    checkSize(file) {
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      if (mbSize > 20) {
        this.sizeExceed = true;
      } else {
        this.sizeExceed = false;
      }
    },
    createAlbum(fd) {
      this.isBusy = true;
      albumService
        .store(fd)
        .then((response) => {
          this.isBusy = false;
          this.resetForm();
          this.hideModal();
          this.$emit("refresh_album");
          this.$snotify.success("Information added");
        })
        .catch((error) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
          // console.log(error);
        });
    },
    resetForm() {
      this.$v.album.$reset();
      this.edit = false;
      this.album = {
        title: null,
        is_active: true,
      };
    },
  },
};
</script>
